<template>
  <div class="storage-container">
    <div class="items">
      <StorageListItem v-for="token of props.tokens" :key="token.id" :token="token" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import type { ITokenModel } from '~/types/apiService';

const props = defineProps<{
  tokens: ITokenModel[];
}>();
</script>

<style lang="scss" scoped>
.storage-container {
  margin-top: 30px;
}

.card.storage {
  max-height: calc(100vh - 250px);
  opacity: 1;
  padding: 25px;
  transition: 300ms ease all;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  font-size: 32px;
  color: #fff;
}

.items {
  border-radius: 10px;
  border: 1px solid #1e353e;
  background: #0d242f;
  padding: 28px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  overflow: auto;
}
</style>

<!-- popper custom styles -->
<style>
.customPopper {
  padding: 20px !important;
  border-radius: 15px !important;
  border: 1px solid #2f2f2f !important;
  background: rgba(0, 0, 0, 0.75) !important;
}
</style>
