<template>
  <div class="confirm-popup">
    <div class="overlay" @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()" />
    <div class="confirm-content">
      <h2 class="confirm-content-title">{{ $t('craftingConfirmationTitle') }}</h2>
      <div
        class="confirm-close-btn"
        @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()"
      />

      <div>
        <h4 class="confirm-content-token-name">{{ $t(props.tokenMetadata.label) }}</h4>
        <p v-if="craftingTimeInjected" class="confirm-content-token-time">
          {{ $t('craftingTime') }}: {{ craftingTimeInjected }}
        </p>
        <div v-for="product of recipe?.products" :key="product.tokenAddress" class="confirm-content-item">
          <img :src="props.tokenMetadata.media" :alt="props.tokenMetadata.label" />
          <p>
            <span v-if="new BigNumber(product.quantityMin).eq(product.quantityMax)">
              {{ new BigNumber(product.quantityMax).multipliedBy(craftingMultiplier) }}
            </span>
            <span v-else>
              {{ new BigNumber(product.quantityMin).decimalPlaces(1).multipliedBy(craftingMultiplier) }}-{{
                new BigNumber(product.quantityMax).decimalPlaces(1).multipliedBy(craftingMultiplier)
              }}
            </span>
          </p>
        </div>
      </div>

      <div>
        <p class="confirm-content-confirm-text" v-html="$t('craftingConfirmationText')" />
        <div class="confirm-content-checkbox">
          <el-checkbox id="agree-cb" v-model="agreeToTerms" type="checkbox" class="checkbox" size="large">
            {{ $t('craftingCheckboxAgree') }}
          </el-checkbox>
        </div>
        <button
          class="btn-primary"
          :disabled="!agreeToTerms || isItemCraftingInjected"
          @click="produceItemInjected && produceItemInjected()"
        >
          {{ $t('craftingProduce') }} &nbsp;<span v-if="confirmationNumberRefInjected && isItemCraftingInjected"
            >{{ confirmationNumberRefInjected }} / {{ blockchain.minConfirmationsCount }}</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, defineProps } from 'vue';
import useEnvs from '~/composables/useEnvs';
import type { RecipeProps } from '~/types/crafting';
import type { TTokensConfigItem } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';

const { blockchain } = useEnvs();

const props = defineProps<{
  tokenMetadata: TTokensConfigItem;
  recipe?: RecipeProps;
  craftingMultiplier: number;
}>();

const agreeToTerms = ref(false);
const handleToggleConfirmationModalInjected = inject<() => void>('handleToggleConfirmationModal');
const produceItemInjected = inject<() => void>('handleProduceItem');
const confirmationNumberRefInjected = inject<number>('confirmationNumberRef');
const isItemCraftingInjected = inject<boolean>('isItemCrafting');
const craftingTimeInjected = inject<string>('craftingTime');
</script>

<style lang="scss" scoped>
.confirm-popup {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 2;
  height: 100%;
  width: 100%;

  overflow: hidden;
  border-radius: 15px;

  display: flex;
}

.confirm-close-btn {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;

  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  &:hover {
    opacity: 0.6;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
}

.confirm-content {
  position: relative;
  z-index: 3;
  background-color: rgba(2, 27, 38, 0.8);
  width: 100%;
  max-width: 683px;
  margin: auto;
  padding: 10px;
  border-radius: 10px;

  &-title {
    font-family: Grenze;
    font-size: 32px;
    font-weight: 700;
    line-height: 47.36px;
    text-align: center;
    margin: 0;
    margin-bottom: 25px;
  }

  &-token-name {
    font-family: Grenze;
    font-size: 26px;
    font-weight: 700;
    line-height: 38.48px;
    text-align: center;
  }

  &-token-time {
    font-family: Grenze;
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: center;
    color: #49ddaa;
  }

  &-confirm-text {
    font-family: Grenze;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    max-width: 577px;
    margin: auto;
    > * {
      color: #0083ad !important;
    }
  }

  &-checkbox {
    font-family: Grenze;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.6px;
    text-align: left;
    margin: 20px auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-item {
    height: 100px;
    width: 89px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 35px;

    border: 1px solid #1e353e;
    border-radius: 12px;

    background: #041a27;
    box-shadow: 2px 4px 5px 0px #00000040;
    background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);

    p {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      font-family: Grenze;
      position: relative;
      top: -10px;
      color: #fff;
    }

    img {
      height: 75px;
      width: 75px;
      margin: auto;
      display: block;
    }

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .btn-primary {
    display: block;
    margin: auto;
    width: 200px;
  }
}
</style>
