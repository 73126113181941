import { getPixiWorldSizes } from '#imports';
import { Viewport } from 'pixi-viewport';
import { Application } from 'pixi.js';

export default (PIXI_WORLD_SIZES: ReturnType<typeof getPixiWorldSizes>) => {
  const app = new Application({
    ...PIXI_WORLD_SIZES.rendererSize,
    antialias: false,
    sharedTicker: true,
    powerPreference: 'high-performance'
  });

  app.ticker.maxFPS = 30;
  app.ticker.speed = 0.5;

  const viewport = new Viewport({
    ...PIXI_WORLD_SIZES.viewportSizes,

    events: app.renderer.events
  });

  app.stage.addChild(viewport);

  viewport.drag({ wheel: false }).pinch().wheel({ trackpadPinch: true, wheelZoom: false }).decelerate();

  viewport.clamp({
    direction: 'all',
    underflow: 'center'
  });

  viewport.clampZoom({
    maxWidth: PIXI_WORLD_SIZES.clampMaxWidth,
    maxHeight: PIXI_WORLD_SIZES.clampMaxHeight,
    minWidth: PIXI_WORLD_SIZES.clampMinWidth,
    minHeight: PIXI_WORLD_SIZES.clampMinHeight
  });

  viewport.fit();
  viewport.input.clear();

  return {
    app,
    viewport
  };
};
