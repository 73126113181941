<template>
  <el-tooltip popper-class="customPopper" effect="dark">
    <template #content>
      <div class="tokenTooltip">
        <h4 class="tooltipTitle">
          <img width="35" :src="tokensConfig[token.key as Tokens].media" :alt="token.key" />
          {{ token.name }}
        </h4>
        <div class="tooltipInfoBlocks">
          <div class="tooltipInfoBlock">
            <div class="blockItem">
              <span class="heading">
                <img width="20" :src="tokensConfig[token.key as Tokens].media" :alt="token.key" />
                {{ $t('appStatisticOwnedToken') }}:
              </span>
              <span class="value">{{ format(balance) }}</span>
            </div>
            <div class="blockItem">
              <span class="heading">
                <img width="20" :src="tokensConfig[token.key as Tokens].media" :alt="token.key" />
                {{ $t('storageMobiletokenStaked') }}:
              </span>
              <span class="value">{{ format(totalStaked) }}</span>
            </div>
          </div>

          <div class="tooltipInfoBlock">
            <div class="blockItem">
              <span class="heading">{{ $t('storageMobileTotalShare', { name: token.name }) }}:</span>
              <span class="value">{{ format(apr, 2) }}%</span>
            </div>
            <div class="blockItem">
              <NuxtLink class="blockItem-stats-link" href="/dashboard"
                >{{ $t('storageMobileStatistics') }} <img src="/img/icons/arrowRight.svg" alt="arrowRight"
              /></NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </template>
    <storage-item
      :token-media="tokensConfig[token.key as Tokens].media"
      :owned-value="format(balance)"
      :staked-value="format(totalStaked)"
    />
  </el-tooltip>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, onBeforeMount } from 'vue';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

import PearlApiService from '~/composables/PearlApiService';
import { Tokens, tokensConfig } from '~/utils/constants';
import useEnvs from '~/composables/useEnvs';
import type { ITokenModel } from '~/types/apiService';
import { useTokensReader } from '#imports';

// TODO: make it as reusable function
function format(value: string, decimals?: number): string {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
}

const { blockchain, apiUrl } = useEnvs();
const { address } = useWeb3ModalAccount();
const { staked, availableTokens } = useTokensReader();

const props = defineProps<{
  token: ITokenModel;
}>();

const totalStaked = ref('0');
const balance = ref('0');
const apr = ref('0');

onBeforeMount(() => {
  return { address: address.value };
});

onMounted(async () => {
  if (!address.value) return;
  const [tokenTotalStaked, tokenAvailableBalance, aprPercentage] = await Promise.all([
    staked(address.value, props.token.key),
    availableTokens(address.value || '', blockchain.contracts[props.token.key].addresses.contract),
    PearlApiService.getAprPercent(apiUrl, props.token.tokenContractAddress)
  ]);

  totalStaked.value = new BigNumber(tokenTotalStaked).integerValue().toString();
  balance.value = new BigNumber(tokenAvailableBalance).integerValue().toString();
  apr.value = new BigNumber(aprPercentage.apr ?? 0).toString();
});
</script>

<style lang="scss" scoped>
.tokenTooltip {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
}

.tooltipTitle {
  display: flex;
  gap: 10px;
  color: #88e1ff;
  align-items: center;
}

.tooltipInfoBlocks {
  display: flex;
  gap: 10px;
}

.tooltipInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #0d242f 0%, #0d242f 100%), linear-gradient(0deg, #10364a 0%, #10364a 100%), #14232a;

  .blockItem {
    display: flex;
    flex-direction: column;

    &-stats-link {
      margin-top: auto;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-color: #fff;
      border-radius: 48px;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 18px;
        width: 18px;
        margin-left: 8px;
      }
    }
  }
  .heading {
    color: #8e979c;
    font-family: Grenze, sans-serif;
    font-size: 20px;
    font-weight: 500;
  }

  .value {
    color: #fff;
    font-family: Grenze, sans-serif;
    font-size: 32px;
    font-weight: 500;
  }
}
</style>

<!-- popper custom styles -->
<style>
.customPopper {
  padding: 20px !important;
  border-radius: 15px !important;
  border: 1px solid #2f2f2f !important;
  background: rgba(0, 0, 0, 0.75) !important;
}

.el-popper__arrow {
  display: none;
}
</style>
