<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-top">
        <div class="w-layout-grid grid-footer-v1">
          <div id="footer-logo-id-wrapper">
            <div class="inner-container _400px---tablet _100---mbp">
              <div class="inner-container _350px---mbl _100---mbp">
                <a href="/" class="footer-logo-wrapper w-inline-block">
                  <img src="/img/logo.png" alt="" class="footer-logo" />
                </a>
              </div>
            </div>
            <div class="divider _32px" />
            <div class="social-media-grid">
              <a href="https://twitter.com/PearlDiver_O" target="_blank" class="social-media-icon">
                <img src="/img/icons/xSocial.svg" height="30" alt="x" />
              </a>
              <!-- <a href="https://instagram.com/" target="_blank" class="social-media-icon"></a> -->
              <a href="https://discord.gg/mgMQdnevtR" target="_blank" class="social-media-icon"></a>
              <a href="https://t.me/pearldiverannouncements" target="_blank" class="social-media-icon"></a>
            </div>
          </div>
          <div class="inner-container _350px---mbl">
            <div class="display-4 mg-bottom-40px">{{ $t('footerGameSections') }}</div>
            <div class="w-layout-grid grid-2-columns footer-lists-wrapper">
              <ul role="list" class="footer-list-wrapper">
                <li class="footer-list-item">
                  <a :href="landingUrl" class="footer-link">{{ $t('menuHome') }}</a>
                </li>
                <li class="footer-list-item">
                  <NuxtLink to="/story" class="footer-link"> {{ $t('menuStory') }} </NuxtLink>
                </li>
                <li class="footer-list-item">
                  <NuxtLink to="/" class="footer-link"> {{ $t('menuMarketplace') }} </NuxtLink>
                </li>
                <li class="footer-list-item">
                  <NuxtLink to="/dashboard" class="footer-link"> {{ $t('menuDashboard') }} </NuxtLink>
                </li>
              </ul>
              <ul role="list" class="footer-list-wrapper">
                <li class="footer-list-item">
                  <a
                    href="https://pearl-diver.gitbook.io/pearl-diver-whitepaper/"
                    target="_blank"
                    class="footer-link"
                    >{{ $t('footerWhitepaper') }}</a
                  >
                </li>
                <li class="footer-list-item">
                  <a
                    href="https://pearl-diver.gitbook.io/pearl-diver-whitepaper/tokenomics/general-token-information"
                    target="_blank"
                    class="footer-link"
                    >{{ $t('footerTokenomics') }}</a
                  >
                </li>
                <!-- <li class="footer-list-item">
                  <a
                    :href="`${landingUrl}/coming-soon.html`"
                    target="_blank"
                    class="footer-link"
                  >Smart Contract</a>
                </li> -->
              </ul>
            </div>
          </div>
          <div id="w-node-_313b9b56-60d7-5321-03e8-41f2f58714ff-1ee5f160">
            <div class="display-4 mg-bottom-40px">{{ $t('footerLegal') }}</div>
            <ul role="list" class="footer-list-wrapper">
              <li class="footer-list-item">
                <a :href="'/terms_and_conditions.pdf'" target="_blank" class="footer-link">{{
                  $t('footerTermsConditions')
                }}</a>
              </li>
              <li class="footer-list-item">
                <a :href="'/privacy_policy.pdf'" target="_blank" class="footer-link">{{ $t('footerPrivacyPolicy') }}</a>
              </li>
              <!-- <li class="footer-list-item">
                <a
                  :href="`${landingUrl}/coming-soon.html`"
                  target="_blank"
                  class="footer-link"
                >Smart Contract Audit </a>
              </li> -->
              <li class="footer-list-item">
                <a href="mailto:support@pearldivergame.com" class="footer-link">{{ $t('footerSupport') }} </a>
              </li>
            </ul>
          </div>
          <div id="captain-wrapper" class="footer-last">
            <div class="display-4 mg-bottom-40px">{{ $t('footerSubscribeToNewsletter') }}</div>
            <div
              data-w-id="313b9b56-60d7-5321-03e8-41f2f587150f"
              class="instagram-feed---image-wrapper subscribe w-inline-block"
              @click="isShowSubscriptionForm = true"
            >
              <img src="/img/pirate_subscribe.png" loading="eager" alt="" class="instagram-feed---image image" />
              <div class="instagram-feed---image-overlay radial">
                <img
                  src="https://uploads-ssl.webflow.com/647f311bff65ecf85cb409e6/647f311cff65ecf85cb40b6a_apply-icon-generativeai-x-webflow-template.svg"
                  loading="eager"
                  alt="Apply For This Position Icon - GenerativeAI X Webflow Template"
                  class="instagram-feed---icon-inside"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        v-if="isShowSubscriptionForm"
        v-model="isShowSubscriptionForm"
        title="Subscription Form"
        :fullscreen="$device.isMobile"
        class="buy-popup"
        :append-to-body="true"
      >
        <el-form ref="subscriptionFormRef" :model="subscriptionForm" :rules="rules" @submit.prevent>
          <el-form-item label="" prop="email">
            <el-input v-model="subscriptionForm.email" name="email" placeholder="Email address" />
          </el-form-item>
          <div class="btns-wrapper">
            <button :disabled="!isEmailValid" class="btn-primary" size="large" @click="submitSubscribeHandler">
              <span>{{ $t('footerSubscribe') }}</span>
            </button>
          </div>
        </el-form>
      </el-dialog>

      <div class="footer-bottom">
        <div class="inner-container _500px---mbl center">
          <p class="mg-bottom-0">©{{ new Date().getFullYear() }} – {{ $t('footerPerlDiverIsland') }}.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type { FormInstance, FormRules } from 'element-plus';
import { ElNotification } from 'element-plus';
import { reactive, ref } from 'vue';
import PearlApiService from '~/composables/PearlApiService';
import { ERRORS_CONFIG } from '~/utils/constants';
import type { TSubscribeEmailModel } from '~/types/apiService';
import useEnvs from '~/composables/useEnvs';
import { useI18n } from '#imports';

const { t } = useI18n();

const { landingUrl, apiUrl } = useEnvs();
const isShowSubscriptionForm = ref(false);
const subscriptionFormRef = ref<FormInstance>();
const isEmailValid = ref(false);
const subscriptionForm = reactive({
  email: ''
});

const validateEmail = (rule: any, value: string, callback: any) => {
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (!value.match(mailformat)) {
    callback(new Error(t('correctEmailError')));
    isEmailValid.value = false;
  } else {
    isEmailValid.value = true;
  }
};

const rules = reactive<FormRules>({
  email: [{ validator: validateEmail, trigger: 'change' }]
});

const submitSubscribeHandler = async () => {
  const subscribePayload: TSubscribeEmailModel = {
    contact: {
      channels: [{ type: 'email', value: subscriptionForm.email }]
    }
  };
  const subscribeRes = await PearlApiService.subscribeEmail(apiUrl, subscribePayload);
  if (subscribeRes?.data?.id) {
    ElNotification.success({
      title: t('successSubscriptionDoneMsg'),
      message: t('successSubscriptionMsg')
    });
    isShowSubscriptionForm.value = false;
    subscriptionForm.email = '';
  } else if (subscribeRes?.status === 429) {
    ElNotification.error(ERRORS_CONFIG.apiErrors.emailSubscription.frequentRequest);
  } else {
    ElNotification.error({
      title: '',
      message: subscribeRes?.data?.message
    });
  }
};
</script>

<style lang="scss">
footer {
  background: var(--blue-1);
  box-sizing: border-box;
  border-top: 1px solid var(--blue-2);
}

.footer-wrapper {
  max-width: 1340px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-flex;
}

.icon-pencil {
  background: url('~/public/img/icons/pencil.png') center center no-repeat;
  background-size: contain;
}

.icon-base {
  background: url('~/public/img/icons/database.png') center center no-repeat;
  background-size: contain;
}

.icon-paper {
  background: url('~/public/img/icons/paper.png') center center no-repeat;
  background-size: contain;
}
</style>

<style lang="scss">
.inner-container.center {
  margin-left: auto;
  margin-right: auto;
}

.footer-bottom {
  text-align: center;
  border-top: 1px solid var(--blue-2);
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-top {
  padding-top: 115px;
  padding-bottom: 115px;
}

.footer-logo-wrapper {
  max-width: 279px;
  margin-bottom: 12px;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.grid-2-columns.footer-lists-wrapper {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-columns: auto auto;
  justify-content: stretch;
  align-items: start;
}

.grid-footer-v1 {
  grid-column-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 300px) minmax(auto, 327px) minmax(119px, 171px) minmax(200px, 350px);
  justify-content: space-between;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .footer-top {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-logo-wrapper {
    max-width: 233px;
  }

  #footer-logo-id-wrapper {
    grid-area: span 1 / span 2 / span 1 / span 2;
    grid-column-start: span 2;
    grid-column-end: span 2;
    justify-self: center;
  }

  .social-media-grid {
    justify-content: center;
  }

  .footer-bottom {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .inner-container._400px---mbp {
    max-width: 400px;
  }

  #captain-wrapper {
    grid-area: 3 / 1 / 4 / 3;
  }
}

@media screen and (max-width: 767px) {
  .footer-logo-wrapper {
    max-width: 194px;
  }

  .footer-top {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  #footer-logo-id-wrapper {
    grid-column: span 2 / span 2;
  }

  .footer-bottom {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #captain-wrapper {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: auto;
  }
}

@media screen and (max-width: 479px) {
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
  }

  #footer-logo-id-wrapper {
    grid-column: span 1 / span 1;
  }

  .mg-bottom-0 {
    margin-bottom: 5px;
  }

  .inner-container._100---mbp {
    max-width: 100%;
  }

  #captain-wrapper {
    grid-column: span 1 / span 1;
  }
}

@media screen and (max-width: 479px) {
  .footer-top {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .grid-footer-v1 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-footer-v1 {
    grid-column-gap: 20px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr minmax(auto, 0.75fr);
  }
}

@media screen and (max-width: 479px) {
  .grid-footer-v1 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}

.social-media-grid {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.social-media-icon {
  color: #fff;
  font-family:
    Social Icons Font,
    sans-serif;
  font-size: 30px;
  width: 30px;
  line-height: 1em;
  text-decoration: none;
  transition:
    transform 0.3s,
    color 0.3s;

  &:hover {
    color: #939393;
    transform: scale(1.1);
  }
}

@media screen and (max-width: 767px) {
  .inner-container._350px---mbl {
    max-width: 350px;
  }
}

.display-4 {
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 26px;
  font-weight: 800;
  line-height: 1.417em;
}

@media screen and (max-width: 991px) {
  .display-4 {
    font-size: 22px;
  }
  .display-4.mg-bottom-40px {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .display-4.mg-bottom-40px {
    margin-bottom: 20px;
  }
  .display-4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 479px) {
  .display-4.mg-bottom-40px {
    margin-bottom: 16px;
  }
}

.footer-list-wrapper {
  grid-row-gap: 18px;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: grid;
  margin-top: 0px;
}

.footer-list-item {
  margin-bottom: 0;
  padding-left: 0;
  line-height: 1.111em;
}

.footer-link {
  font-family: Grenze, sans-serif;
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
  transition:
    transform 0.3s,
    color 0.3s;
  display: inline-block;
}

.footer-link:hover {
  color: #fff;
  transform: translate(6px);
}

.instagram-feed---image {
  width: 100%;
  object-fit: cover;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: 200ms ease all;
}

.instagram-feed---image-wrapper {
  &:hover {
    .instagram-feed---image {
      transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    }
  }
}

.divider._32px {
  margin-top: 32px;
  margin-bottom: 32px;
}

.footer-logo {
  width: 100%;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 991px) {
  .footer-last {
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    display: flex;
  }
}

.instagram-feed---icon-inside {
  opacity: 0;
  transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-8deg) skew(0deg);
  transform-style: preserve-3d;
  max-width: 40px;
  min-width: 40px;
  transition: 200ms ease all;
}

.instagram-feed---image-overlay.radial {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: radial-gradient(circle farthest-side, rgba(2, 22, 31, 0.8), rgba(0, 0, 0, 0));
}

.instagram-feed---image-overlay {
  z-index: 1;
  transform-origin: 0%;
  background-color: rgba(0, 0, 0, 0.65);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  opacity: 0;
  transition: 200ms ease all;

  &:hover {
    opacity: 1;
    .instagram-feed---icon-inside {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-8deg) skew(0deg);
    }
  }
}

.instagram-feed---image-wrapper {
  z-index: 1;
  border-radius: 6px;
  display: flex;
  position: relative;
  transform: translate(0);
}

.mg-bottom-40px {
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .instagram-feed---image-wrapper.subscribe {
    width: 40%;
  }
}

@media screen and (max-width: 479px) {
  .instagram-feed---image-wrapper.subscribe {
    width: 80%;
  }
}
</style>
