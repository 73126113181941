<template>
  <div v-for="product of recipe?.products" :key="product.tokenAddress" class="ready-for-claim">
    <figure class="crafting-item-figure">
      <img :src="tokenMetadataComputed?.media" :alt="tokenMetadataComputed?.label" />
      <p>
        <span v-if="new BigNumber(product.quantityMin).eq(product.quantityMax)">
          {{ new BigNumber(product.quantityMax).multipliedBy(props.activityItem.available) }}
        </span>
        <span v-else>
          {{ new BigNumber(product.quantityMin).decimalPlaces(1).multipliedBy(props.activityItem.available) }}-{{
            new BigNumber(product.quantityMax).decimalPlaces(1).multipliedBy(props.activityItem.available)
          }}
        </span>
      </p>
    </figure>
    <div class="ready-for-claim-content">
      <button
        class="btn-primary"
        type="button"
        :disabled="isLoadingClaimButton"
        @click="claimCraftedTokens(props.activityItem.id)"
      >
        <span>Claim</span>
        &nbsp;<span v-if="confirmationNumberRef && isLoadingClaimButton"
          >{{ confirmationNumberRef }} / {{ blockchain.minConfirmationsCount }} &nbsp;</span
        >

        <img src="/img/icons/chevronRight.svg" alt="chevron right" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, inject } from 'vue';
import { ElNotification } from 'element-plus';
import { BigNumber } from 'bignumber.js';

import type { RecipeProps, UserActivityAvailableForClaim } from '~/types/crafting';
import { type TTokensConfigItem } from '~/utils/constants';
import useEnvs from '~/composables/useEnvs';
import { useMainStore } from '~/stores/mainStore';
import { modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';
import useSendContractMethod from '~/composables/useSendContractMethod';

const { sendContractMethod } = useSendContractMethod();
const refreshListInjected = inject<() => void>('refreshList');

const { blockchain } = useEnvs();
const store = useMainStore();
const { t } = useI18n();

const confirmationNumberRef = ref<number>(0);
const isLoadingClaimButton = ref<boolean>(false);

const props = defineProps<{
  selectedRecipeId: string;
  tokenMetadataComputed: TTokensConfigItem;
  activityItem: UserActivityAvailableForClaim;
  recipe?: RecipeProps;
}>();

const claimCraftedTokens = async (id: number) => {
  isLoadingClaimButton.value = true;
  try {
    const claimTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'claimRecipe',
        methodArguments: [props.selectedRecipeId, id]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!claimTx?.hash) return;
    saveHashToLocalStorage(
      `Claim (${props.recipe?.products.map((product) =>
        new BigNumber(product.quantityMin).eq(product.quantityMax)
          ? BigNumber(product.quantityMin).multipliedBy(props.activityItem.available).decimalPlaces(1)
          : `${new BigNumber(product.quantityMin).multipliedBy(props.activityItem.available).decimalPlaces(1)}-${new BigNumber(product.quantityMax).multipliedBy(props.activityItem.available).decimalPlaces(1)}`
      )}) ${t(props.tokenMetadataComputed?.label || '')}`,
      claimTx.hash
    );
    store.updateVersion();

    await claimTx?.wait(blockchain.minConfirmationsCount);
    const confirmations = await claimTx?.confirmations();

    if (confirmations >= blockchain.minConfirmationsCount && claimTx?.hash) {
      modifyHistoryHashStatus(claimTx.hash, 'Done');
      store.updateVersion();
      ElNotification.success({
        title: 'Token was claimed',
        message: ''
      });

      // refresh after success for optimistic UI
      refreshListInjected && refreshListInjected();
    }
  } catch (error) {
    console.error(error, 'error on claim');
    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  } finally {
    isLoadingClaimButton.value = false;
  }
};
</script>
