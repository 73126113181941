import { Spine } from 'pixi-spine';
import { Sprite } from 'pixi.js';

type TInteractiveObjectUnion = Sprite | Spine;

export function isInteractiveObject(target: unknown): target is TInteractiveObjectUnion {
  return target instanceof Spine || target instanceof Sprite;
}

// usefull for nullable types to avoid making types cast
export function isPredictOrNull<T>(target: T | null): target is T {
  return target !== null;
}
