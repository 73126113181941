<template>
  <li class="crafting-material">
    <img class="crafting-material-img" :src="token.media" :alt="token.label" />
    <!-- "placeholderWidthString" is the longest string of the material cards 
    to define the equal width of the cards (by the largest one) -->
    <p class="placeholderWidthString">{{ placeholderWidthString }}</p>
    <p v-if="!isTokenBalanceLoading" class="crafting-material-amount">
      <span :class="[!isEnoughTokens && 'notEnough']">{{ availableTokensFormatted }}</span
      >/<span>{{ Number(props.ingredient.quantity) * produceAmount }}</span>
    </p>
  </li>
</template>

<script setup lang="ts">
import { defineProps, computed, ref, onMounted } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { BigNumber } from 'bignumber.js';

import { tokenMetaData } from '~/utils';
import type { RecipeIngredient } from '~/types/crafting';
import useEnvs from '~/composables/useEnvs';

import { useTokensReader } from '#imports';

const { blockchain } = useEnvs();
const { address } = useWeb3ModalAccount();
const { availableTokens } = useTokensReader();
const store = useMainStore();
const currentlyAvailableTokens = ref<number>(0);
const isEnoughTokens = ref<boolean>(false);
const isTokenBalanceLoading = ref<boolean>(true);

const props = defineProps<{
  ingredient: RecipeIngredient;
  produceAmount: number;
  placeholderWidthString: string;
}>();

const token = tokenMetaData(blockchain.contracts, props.ingredient.tokenAddress);

store.$onAction(async ({ name }) => {
  if (name === 'updateVersion') {
    currentlyAvailableTokens.value = address?.value
      ? Number(await availableTokens(address?.value, props.ingredient.tokenAddress))
      : 0;
  }
});

onMounted(async () => {
  const totalTokensOnBalance = address?.value
    ? await availableTokens(address?.value, props.ingredient.tokenAddress)
    : 0;
  const requiredAmountForCraft = props.ingredient.quantity;

  currentlyAvailableTokens.value = +totalTokensOnBalance;

  if (Number(totalTokensOnBalance) >= Number(requiredAmountForCraft) * props.produceAmount) {
    isEnoughTokens.value = true;
  }

  isTokenBalanceLoading.value = false;
});

const availableTokensFormatted = computed(() => {
  if (currentlyAvailableTokens.value / 1000000 <= 1) return new BigNumber(currentlyAvailableTokens.value).toFormat(0);

  return `${new BigNumber(currentlyAvailableTokens.value).dividedBy(1000).toFormat(0)}k`;
});
</script>

<style scoped lang="scss">
.notEnough {
  color: red;
}

.placeholderWidthString {
  visibility: hidden;
  line-height: 0;
  font-size: 18px;
}
</style>
