<template>
  <svg width="18" height="13" viewBox="0 0 18 16" :color="props.color" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4935 6.76237L7.8482 1.49342L9.21284 0.031311L16.7128 7.03131L17.4695 7.73756L16.7376 8.46947L9.23762 15.9695L7.82341 14.5553L13.6163 8.76237H0.530518V6.76237H13.4935Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps<{ color?: string }>();
</script>
