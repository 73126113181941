<template>
  <div class="available-claim">
    <div class="available-claim-info">
      <div class="recipe-row-token-info">
        <img :src="props.tokenMetadata.media" :alt="props.tokenMetadata.label" />
        <h3>{{ props.tokenMetadata.methodName }} ({{ props.runningRecipe.multiplyModifier }})</h3>
      </div>
      <div v-if="timeLeft > 0" class="recipe-row-token-info">
        <p>{{ formatCountdownTime(timeLeft) }}</p>
      </div>
    </div>
    <div
      :class="[
        'available-claim-progress',
        percent <= CRAFTING_PERCENT ? 'default' : 'halfDone',
        !isMineRecipe && 'notMine'
      ]"
    >
      <p class="available-claim-progress-percent">{{ percent >= 100 ? '100' : percent.toFixed(2) }}%</p>
      <div v-for="(_, index) of barsAmount" :key="index" class="available-claim-progress-bar" />
      <div
        :class="[
          'available-claim-progress-fill',
          percent <= CRAFTING_PERCENT ? 'default' : 'halfDone',
          !isMineRecipe && 'notMine'
        ]"
        :style="{
          width: `${percent}%`
        }"
      />
    </div>
    <div v-if="isMineRecipe" class="available-claim-buttons">
      <div v-for="product of recipe?.products" :key="product.tokenAddress" class="btn-outline">
        <p v-if="tokensForClaimRef > 0">
          <span v-if="new BigNumber(product.quantityMin).eq(product.quantityMax)">
            {{ new BigNumber(product.quantityMax).multipliedBy(tokensForClaimRef) }}
          </span>
          <span v-else>
            {{ new BigNumber(product.quantityMin).decimalPlaces(1).multipliedBy(tokensForClaimRef) }}-{{
              new BigNumber(product.quantityMax).decimalPlaces(1).multipliedBy(tokensForClaimRef)
            }}
          </span>
        </p>
        <p v-else>0</p>
      </div>
      <button
        class="btn-primary"
        type="button"
        :disabled="tokensForClaimRef <= 0 || isLoadingClaimButton"
        @click="claimCraftedTokens(props.runningRecipe.userRecipeIndex)"
      >
        <span
          >{{ $t('craftingClaim') }} &nbsp;<span v-if="confirmationNumberRef && isLoadingClaimButton"
            >{{ confirmationNumberRef }} / {{ blockchain.minConfirmationsCount }}</span
          >
        </span>
        <img src="/img/icons/chevronRight.svg" alt="chevron right" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps, computed, inject, watch } from 'vue';
import { ElNotification } from 'element-plus';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { BigNumber } from 'bignumber.js';

import { type TTokensConfigItem, CRAFTING_PERCENT } from '~/utils/constants';
import type { BuildingProps, RunningRecipeProps, FormattedActivityItemProps, RecipeProps } from '~/types/crafting';
import useSendContractMethod from '~/composables/useSendContractMethod';
import useEnvs from '~/composables/useEnvs';
import { useMainStore } from '~/stores/mainStore';
import { modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';

const { address } = useWeb3ModalAccount();
const { sendContractMethod } = useSendContractMethod();
const { blockchain, apiUrl } = useEnvs();
const store = useMainStore();
const { t } = useI18n();

const props = defineProps<{
  tokenMetadata: TTokensConfigItem;
  runningRecipe: RunningRecipeProps;
  recipeId: string;
}>();

const { data: recipe } = await useFetch<RecipeProps>(() => `/crafting/recipes/${props.recipeId}`, {
  baseURL: apiUrl,
  watch: [() => props.recipeId]
});

const isMineRecipe = props.runningRecipe.userAddress === address.value;

const refreshActivityListInjected = inject<() => FormattedActivityItemProps[]>('refreshList');
const refreshRunningRecipesInjected = inject<() => void>('refreshRunningRecipes');
const building = inject<BuildingProps>('building');
const userActivityItems = inject<any>('userActivity');
const endTime = new Date(props.runningRecipe.craftEndTime).getTime();
const startTime = new Date(props.runningRecipe.craftStartTime).getTime();

function calculateTokensAvailable(timeRemaining: number, totalDuration: number, totalTokens: number) {
  const elapsedTime = totalDuration - timeRemaining;

  const durationPerToken = totalDuration / totalTokens;

  return Math.floor(elapsedTime / durationPerToken);
}

function calculateTimeLeftPercent(startTime: number, endTime: number, currentTime: number) {
  const totalTime = endTime - startTime;
  const timePassed = currentTime - startTime;

  if (totalTime <= 0 || timePassed <= 0) {
    return 0;
  }

  return (timePassed / totalTime) * 100;
}

function formatCountdownTime(seconds: number) {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedTime = [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    secs.toString().padStart(2, '0')
  ].join(':');

  if (seconds >= 172800) {
    // 48 hours = 172800 seconds
    return `${days} day${days < 2 ? '' : 's'} ${formattedTime}`;
  }

  return formattedTime;
}

const percent = ref<number>(calculateTimeLeftPercent(startTime, endTime, Date.now()));
const timeLeft = ref<number>((endTime - Date.now()) / 1000);
const timerTokensForClaimRef = ref<number>(0);
const tokensForClaimRef = ref<number>(0);
const confirmationNumberRef = ref<number>(0);
const isLoadingClaimButton = ref<boolean>(false);

watch(timerTokensForClaimRef, async () => {
  if (userActivityItems && refreshActivityListInjected) {
    await refreshActivityListInjected();
    const currentActivityItem = userActivityItems.value.find((item: any) => {
      return item.buildingHash === building?.buildingHash && startTime === item.date * 1000;
    });

    tokensForClaimRef.value = currentActivityItem ? currentActivityItem.available : 0;
  }
});

const barsAmount = computed(() => {
  return [...new Array(props.runningRecipe.multiplyModifier - 1)];
});

function startCountdown() {
  const interval = setInterval(() => {
    if (timeLeft.value > 0) {
      timeLeft.value = timeLeft.value - 1;
      percent.value = calculateTimeLeftPercent(startTime, endTime, Date.now());

      timerTokensForClaimRef.value = Math.floor(
        calculateTokensAvailable(
          timeLeft.value,
          props.runningRecipe.craftDuration,
          props.runningRecipe.multiplyModifier
        )
      );
    } else if (percent.value >= 100) {
      percent.value = 0;
      timerTokensForClaimRef.value = 0;
      clearInterval(interval);
      refreshRunningRecipesInjected && refreshRunningRecipesInjected();
      refreshActivityListInjected && refreshActivityListInjected();
    } else {
      percent.value = 0;
      timerTokensForClaimRef.value = 0;
      clearInterval(interval);
      refreshRunningRecipesInjected && refreshRunningRecipesInjected();
      refreshActivityListInjected && refreshActivityListInjected();
    }
  }, 1000);
}

onMounted(() => {
  startCountdown();
});

const claimCraftedTokens = async (id: number) => {
  isLoadingClaimButton.value = true;
  try {
    confirmationNumberRef.value = 0;

    const claimTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'claimRecipe',
        methodArguments: [props.recipeId, id]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!claimTx?.hash) return;
    saveHashToLocalStorage(
      `Claim (${recipe.value?.products.map((product) =>
        new BigNumber(product.quantityMin).eq(product.quantityMax)
          ? BigNumber(product.quantityMin).multipliedBy(tokensForClaimRef.value).decimalPlaces(1)
          : `${new BigNumber(product.quantityMin).multipliedBy(tokensForClaimRef.value).decimalPlaces(1)}-${new BigNumber(product.quantityMax).multipliedBy(tokensForClaimRef.value).decimalPlaces(1)}`
      )} ${t(props.tokenMetadata?.label || '')})`,
      claimTx.hash
    );

    await claimTx?.wait(blockchain.minConfirmationsCount);
    const confirmations = await claimTx?.confirmations();

    if (confirmations >= blockchain.minConfirmationsCount && claimTx?.hash) {
      ElNotification.success({
        title: 'Token was claimed',
        message: ''
      });

      modifyHistoryHashStatus(claimTx.hash, 'Done');
      store.updateVersion();
      refreshActivityListInjected && refreshActivityListInjected();
      tokensForClaimRef.value = 0;
    }
  } catch (error) {
    console.error(error, 'error on claim');
    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  } finally {
    isLoadingClaimButton.value = false;
  }
};
</script>

<style lang="scss" scoped>
.available-claim {
  padding: 0 14px 14px 14px;
  margin-bottom: 6px;

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 40px;
      width: 40px;
    }

    h3 {
      font-family: Eczar;
      font-size: 20px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: #fff;
    }

    p {
      font-family: Grenze;
      font-size: 24px;
      font-weight: 500;
      line-height: 35.52px;
      text-align: left;
      color: #fff;
    }
  }

  &-progress {
    position: relative;
    border-radius: 8px;
    border: 1px solid #49ddaa;
    border-radius: 8px;
    margin-bottom: 10px;
    height: 25px;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    transition: all 0.3s ease;

    &.notMine {
      border-color: #10364a;
    }

    &-percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-family: Grenze;
      font-size: 18px;
      font-weight: 900;
      line-height: 19.6px;
      color: #fff;
      z-index: 3;
    }

    &-bar {
      height: 100%;
      width: 2px;
      background: #fff;
      opacity: 0.2;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease;
    }

    &-fill {
      height: 100%;
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;

      background: linear-gradient(90deg, #01c0fe 0%, #60ff7c 100%);

      &.notMine {
        background: linear-gradient(90deg, #c9c9c9 0%, rgba(201, 201, 201, 0.5) 99.89%);
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      &:nth-child(1) {
        color: #49ddaa;
        pointer-events: none;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
}
</style>
