<template>
  <div>
    <div v-if="!isRunningRecipesLoading" class="crafting-info-row">
      <p>
        {{ $t('craftingRecipesRunning') }}: <span>{{ runningRecipes?.length }}</span>
      </p>
      <!-- !!! Temporary hide this block until the websocket will be fixed on BE, please do not delete -->

      <!-- <p>
        {{ $t('craftingViewingNow') }}: <span>{{ activeUsersModalInjected?.craftingViewersCount }}</span>
      </p> -->
    </div>

    <div v-if="!isRecipeLoading && recipeToCraft && recipeToCraft.duration > 0" class="crafting-info-row">
      <span class="crafting-info-row-time"> {{ $t('craftingTime') }}: {{ craftingTime }}</span>
    </div>

    <div v-if="!isRecipeLoading" class="crafting-recipe">
      <ul class="crafting-materials">
        <CraftingMaterial
          v-for="(item, i) of recipeToCraft?.ingredients"
          :key="i"
          :ingredient="item"
          :produce-amount="produceAmount"
          :placeholder-width-string="materialWidthPlaceholderString"
        />
      </ul>
      <div class="crafting-arrow">→</div>
      <div v-for="finalProduct of recipeToCraft?.products" :key="finalProduct.productId" class="crafting-crafted">
        <img v-if="isNft" class="random-nft" src="/img/icons/random-nft.svg" alt="nft-random" />
        <img
          class="crafted-item-img"
          :src="tokenMetaData(blockchain.contracts, finalProduct.tokenAddress).media"
          :alt="tokenMetaData(blockchain.contracts, finalProduct.tokenAddress).label"
        />
        <p v-if="isNft" class="valuable">
          <span v-if="computedRecipeId === '9'">{{ $t('coreRare') }}</span>
          <span v-else-if="computedRecipeId === '7'">{{ $t('coreUncommon') }}</span>
          <span v-else>{{ $t('coreCommon') }}</span>
        </p>
        <p v-if="!isNft">
          <span v-if="new BigNumber(finalProduct.quantityMin).eq(finalProduct.quantityMax)">
            {{ new BigNumber(finalProduct.quantityMax).multipliedBy(produceAmount || 0) }}
          </span>
          <span v-else>
            {{ new BigNumber(finalProduct.quantityMin).decimalPlaces(1).multipliedBy(produceAmount || 0) }}-{{
              new BigNumber(finalProduct.quantityMax).decimalPlaces(1).multipliedBy(produceAmount || 0)
            }}
          </span>
        </p>
        <p v-else>{{ finalProduct.nftValueMin }} - {{ finalProduct.nftValueMax }}</p>
      </div>
    </div>

    <div class="crafting-buttons">
      <el-input-number
        v-model="produceAmount"
        :step="1"
        :step-strictly="true"
        :validate-event="false"
        :min="1"
        :max="maxAmountAvailableForCraft"
        :value-on-clear="1"
      />
      <button
        class="btn-outline btn-max"
        type="button"
        :disabled="isItemCraftingInjected || maxAmountAvailableForCraft <= 0"
        @click="setMaxProduceItem"
      >
        {{ $t('craftingMax') }} ({{ maxAmountAvailableForCraft }})
      </button>
      <button
        class="btn-primary"
        :disabled="
          isItemCraftingInjected ||
          maxAmountAvailableForCraft <= 0 ||
          produceAmount > maxAmountAvailableForCraft ||
          !isEnoughTokens
        "
        @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()"
      >
        {{ $t('craftingProduce') }}
        <span v-if="confirmationNumberRefInjected && isItemCraftingInjected"
          >{{ confirmationNumberRefInjected }} / {{ blockchain.minConfirmationsCount }}</span
        >
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from 'vue';
import { CraftingMaterial } from '#components';
import { BigNumber } from 'bignumber.js';
import { tokenMetaData } from '~/utils';
import { useFetch } from '#app';
import useEnvs from '~/composables/useEnvs';

import type {
  // ActiveUsersModalProps,
  BuildingProps,
  RecipeProps,
  RecipeProduct,
  RunningRecipeProps
} from '~/types/crafting';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

const props = defineProps<{
  recipeId: string;
}>();

const computedRecipeId = computed(() => props.recipeId);
const building = inject<BuildingProps>('building');
const confirmationNumberRefInjected = inject<number>('confirmationNumberRef');
const isItemCraftingInjected = inject<boolean>('isItemCrafting');

const handleToggleConfirmationModalInjected = inject<() => void>('handleToggleConfirmationModal');
const setProductMultiplierInjected = inject<(multiplier: number) => void>('setProductMultiplier');
const setCraftingTimeInjected = inject<(time: string) => void>('setCraftingTime');
// const activeUsersModalInjected = inject<ActiveUsersModalProps>('activeUsersModal');

const { apiUrl, blockchain } = useEnvs();
const { availableTokens } = useTokensReader();
const { address } = useWeb3ModalAccount();
const store = useMainStore();

const {
  data: runningRecipes,
  pending: isRunningRecipesLoading,
  refresh: refreshRunningRecipes
} = await useFetch<RunningRecipeProps[]>(() => `/crafting/recipes/in-queue`, {
  baseURL: apiUrl,
  query: {
    recipeId: computedRecipeId,
    buildingHash: building?.buildingHash
  }
});

store.$onAction(async ({ name }) => {
  if (name === 'updateVersion') {
    refreshRunningRecipes();
  }
});

const { data: recipes } = await useFetch<RecipeProps[]>(`/crafting/buildings/${building?.buildingHash}/recipes`, {
  baseURL: apiUrl
});

const { data: recipeToCraft, pending: isRecipeLoading } = await useFetch<RecipeProps>(
  () => `/crafting/recipes/${props.recipeId}`,
  {
    baseURL: apiUrl
  }
);

const isNft = computed(() => {
  if (!recipeToCraft) return false;

  return (
    recipeToCraft.value &&
    recipeToCraft.value.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});

const maxAmountAvailableForCraft = ref(recipeToCraft.value?.maxQueue || 1);
const isEnoughTokens = ref(true);
const produceAmount = ref(1);
const materialWidthPlaceholderString = ref('');

watch(
  [recipeToCraft, address, () => recipeToCraft.value?.recipeId],
  async ([newRecipeToCraft, newAddress]) => {
    const maxQueue = recipes.value?.find((recipe) => recipe.recipeId === recipeToCraft.value?.recipeId)?.maxQueue || 1;

    if (!newAddress || !newRecipeToCraft) {
      maxAmountAvailableForCraft.value = maxQueue;
      return;
    }
    const balanceAbilityToProduce = newRecipeToCraft.ingredients.map(async (ingredient) => {
      const ingredientBalance = !newAddress ? 0 : await availableTokens(newAddress, ingredient.tokenAddress);

      const placeholderString = `${BigNumber(ingredientBalance).toFormat(0)} / ${BigNumber(ingredient.quantity)}`;
      if (placeholderString.length > materialWidthPlaceholderString.value.length) {
        materialWidthPlaceholderString.value = placeholderString;
      }

      return new BigNumber(ingredientBalance).dividedBy(new BigNumber(ingredient.quantity)).toNumber();
    });
    const promises = await Promise.all(balanceAbilityToProduce);
    if (promises.some((tokenProduceAbility) => tokenProduceAbility < 1)) isEnoughTokens.value = false;
    const minRecipesCount = Math.floor(Math.min(...promises));
    const maxAvailable = minRecipesCount >= maxQueue ? maxQueue : minRecipesCount;
    if (newRecipeToCraft && newRecipeToCraft?.duration > 0) {
      maxAmountAvailableForCraft.value = (maxAvailable || 1) - (runningRecipes.value?.length || 0);
    } else {
      maxAmountAvailableForCraft.value = maxAvailable || 1;
    }
  },
  { immediate: true }
);

watch(produceAmount, (newValue) => {
  setProductMultiplierInjected && setProductMultiplierInjected(newValue);
});

watch(props, () => {
  produceAmount.value = 1;
  refreshRunningRecipes();
});

const craftingTime = computed(() => {
  if (recipeToCraft && recipeToCraft.value) {
    const currentTime = new Date(Date.now()).toLocaleTimeString('en-GB');

    const endTime = new Date(
      // the more amount we have, the more time eventually would take to craft
      Date.now() + recipeToCraft.value.duration * produceAmount.value * 1000
    ).toLocaleTimeString('en-GB');
    const craftingTimeEvaluated = `${currentTime} - ${endTime}`;
    setCraftingTimeInjected && setCraftingTimeInjected(craftingTimeEvaluated);

    return craftingTimeEvaluated;
  }

  return '';
});

const setMaxProduceItem = () => {
  produceAmount.value = maxAmountAvailableForCraft.value;
};
</script>

<style lang="scss">
.crafting-crafted {
  position: relative;

  p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 1rem;
    position: relative;
    top: -10px;
    color: #fff;
  }

  .valuable {
    color: #ffe604;
    font-size: 1.2rem;
  }
}

.crafting-buttons {
  .amount {
    height: 50px;
  }
}

.random-nft {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.crafted-item-img {
  width: 75px;
  height: 75px;
  margin-top: 2px;
  margin-left: 5px;
}

.btn-max {
  border-radius: 12px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 50px;
}
</style>
