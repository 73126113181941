<template>
  <div class="pearl-packs-container">
    <div class="top-content">
      <p class="description">
        {{ $t('pearlMarketDescription') }}
        <span class="description-more">{{ $t('coreMore') }}</span>
      </p>
      <p class="apr">
        {{ $t('appStatisticAPR') }}:
        {{ aprData?.apr ? format(new BigNumber(aprData?.apr).toString(), 2) : '...' }}
        %
      </p>

      <div v-if="Number(earnedBalance) > 0" class="ready-for-claim">
        <div class="ready-for-claim-amount">
          <figure class="crafting-item-figure">
            <img :src="tokensConfig.pearl?.media" :alt="tokensConfig.pearl?.label" />
          </figure>
          <p>{{ format(earnedBalance) }}</p>
        </div>
        <button class="btn-primary" type="button" :loading="loadingClaim" @click="claimRewardHandler">
          <span>Claim</span>
          &nbsp;<span v-if="claimConfNumber && loadingClaim"
            >{{ 0 }} / {{ blockchain.minConfirmationsCount }} &nbsp;</span
          >

          <ChevronRight />
        </button>
      </div>
    </div>

    <div class="w-layout-grid">
      <div v-for="pack in PEARL_PACK_DATA" :key="pack.name" class="offer card pd-48px---32px shop-item w-inline-block">
        <BuyPackButton
          :pack-key="pack.key"
          :price="pack.price"
          :token-reward="pack.tokenReward"
          :pearl-black-reward="pack.pearlBlackReward"
          :name="pack.name"
          :image-path="pack.imagePath"
          :token="pack.token"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BigNumber } from 'bignumber.js';

import type { IPercentAPRResponse } from '~/types/apiService';
import { apiUrls, PEARL_PACK_DATA, tokensConfig } from '~/utils/constants';
import ChevronRight from './icons/ChevronRight.vue';
import { useWalletInfo, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';

const { apiUrl, blockchain } = useEnvs();
const store = useMainStore();
const { address } = useWeb3ModalAccount();
const { walletProvider } = useWeb3ModalProvider();
const { walletInfo } = useWalletInfo();
const { earned } = useTokensReader();
const { claimReward } = useStakingMethods();
const earnedBalance = ref('0');
const claimConfNumber = ref(0);
const loadingClaim = ref(false);
const { $errorHandler } = useNuxtApp();

watch(
  address,
  async (newAddress) => {
    if (newAddress) {
      earnedBalance.value = await earned(newAddress);
    } else earnedBalance.value = '0';
  },
  { immediate: true }
);

const { data: aprData } = useFetch<IPercentAPRResponse>(apiUrls.token.apr, {
  baseURL: apiUrl,
  query: {
    tokenAddress: blockchain.contracts[store.currentToken].addresses.contract
  },
  pick: ['apr'],
  immediate: true
});

const format = (value: string | number, decimals = 2) => {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
};

const claimRewardHandler = async () => {
  if (!address.value || !walletProvider.value) {
    alert('Please unlock wallet!');
    return;
  }
  claimConfNumber.value = 0;

  try {
    loadingClaim.value = true;
    const receipt = await claimReward(() => {
      claimConfNumber.value += 1;
    });

    if (receipt?.hash) {
      await PearlApiService.pushActivity(apiUrl, {
        address: address.value ?? '',
        transactionHash: receipt.hash,
        value: 0,
        type: 'REWARD_CLAIM'
      });
      saveHashToLocalStorage('Claim reward', receipt.hash);
      store.updateVersion();

      await receipt?.wait(blockchain.minConfirmationsCount);
      const confirmations = await receipt?.confirmations();

      if (confirmations >= blockchain.minConfirmationsCount) {
        loadingClaim.value = false;
        modifyHistoryHashStatus(receipt.hash, 'Done');
        store.updateVersion();
      }
    }
  } catch (e: unknown) {
    loadingClaim.value = false;
    if (walletInfo.value?.name) {
      const errorMessage = getCancelErrorMessage(e, walletInfo.value.name);
      $errorHandler('Failed to unlock: ' + errorMessage);
    }
  }
};
</script>

<style lang="scss" scoped>
.top-content {
  margin-bottom: 30px;
}

.ready-for-claim {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 12px;
  width: 320px;
  border: 2px solid #1e353e;

  background: linear-gradient(180deg, #60ff7c 0%, #04202b 100%);
  width: 60%;
  margin: 0 auto;

  &-amount {
    display: flex;
    align-items: center;
  }

  button {
    margin-left: 20px;
    line-height: 32px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #04202b;

    &:hover {
      color: #fff;
    }
    svg {
      margin-left: 8px;
      path {
        fill: currentColor;
        transition: all 0.3s;
      }
    }
  }

  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 800;
    font-size: 24px;
    color: #fff;
    font-family: Eczar;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.description {
  color: #90989d;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
  max-width: 670px;
  margin: auto;

  &-more {
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
  }
}

.apr {
  font-size: 24px;
  color: #fff;
  width: 100%;
  text-align: center;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  display: grid;
  width: 100%;

  border-radius: 10px;
  border: 1px solid #1e353e;
  background: #0d242f;
  padding: 18px;
  width: 100%;

  @media screen and (max-width: 767px) {
    height: auto;
    max-height: 100%;
  }
  max-height: 55vh;
  overflow: auto;
}

.offers-list {
  margin-bottom: 48px;
}

.card {
  overflow: hidden;
  padding: 6px 44px;
}
.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  display: grid;
}

.offers-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--main-text-color);
  font-weight: 700;
  font-family: Grenze, sans-serif;
}

.offers-wrapper {
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid var(--blue-2);
  background: var(--blue-3);
  margin-bottom: 20px;
}

.sale-wrapper,
.shop-wrapper {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.offer {
  display: flex;
  align-items: baseline;
  transition: ease all 200ms;
  cursor: pointer;
}

.card.pd-48px---32px {
  padding: 53px 33px;
  padding-top: 53px;
  padding-bottom: 53px;
}

.card.pd-48px---32px.shop-item {
  color: rgba(255, 255, 255, 0.5);
  background-color: #021b26;
  background-image: radial-gradient(circle closest-corner at 50% 0, #3b7285, rgba(0, 0, 0, 0));
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  transition:
    all 0.3s,
    color 0.3s;

  &:hover {
    background-image: radial-gradient(circle closest-corner at 50% 0, #3faa58, rgba(0, 0, 0, 0));
  }
}

@media screen and (max-width: 991px) {
  .card.pd-48px---32px.shop-item {
    background-image: radial-gradient(circle farthest-side at 50% 0, #3b7285, rgba(0, 0, 0, 0) 50%);
    flex-direction: column;
    align-items: stretch;
    padding: 16px 20px;
    display: flex;
  }

  .w-layout-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .card.pd-48px---32px.shop-item {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 479px) {
  .card.pd-48px---32px.shop-item {
    padding: 12px 8px;
    font-size: 12px;
  }

  .w-layout-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-wrapper {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
  align-items: flex-start;
}

@media screen and (max-width: 991px) {
  .grid-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-wrapper {
    flex-direction: column;
    display: flex;
  }
}
</style>

<style scoped lang="scss">
.el-dialog {
  max-width: 100% !important;
  &__body {
    max-width: 100% !important;
  }
  &__title {
    font-size: 32px !important;
  }
}
</style>
