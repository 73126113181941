<template>
  <div class="token">
    <h4 class="tokenTitle">
      <img width="55" :src="tokensConfig[token.key as Tokens].media" :alt="token.key" />
      {{ token.name }}
    </h4>
    <div class="tokenInfoBlocks">
      <div class="tokenInfoBlock">
        <div class="blockItem">
          <span class="heading"> {{ $t('appStatisticOwnedToken', { tokenLabel: token.name }) }}: </span>
          <span class="value">
            <img width="26" :src="tokensConfig[token.key as Tokens].media" :alt="token.key" />
            {{ format(balance, 0) }}</span
          >
        </div>
        <div class="blockItem">
          <span class="heading"> {{ $t('storageMobiletokenStaked', { name: token.name }) }}: </span>
          <span class="value">
            <img width="26" :src="tokensConfig[token.key as Tokens].media" :alt="token.key" />
            {{ format(totalStaked, 0) }}</span
          >
        </div>
      </div>

      <div class="tokenInfoBlock">
        <div class="blockItem">
          <span class="heading"> {{ $t('storageMobileTotalShare', { name: token.name }) }}: </span>
          <span class="value">{{ format(apr, 2) }}%</span>
        </div>
        <div class="blockItem">
          <NuxtLink class="blockItem-stats-link" href="/dashboard"
            >{{ $t('storageMobileStatistics') }} <img src="/img/icons/arrowRight.svg" alt="arrowRight"
          /></NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, onBeforeMount } from 'vue';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

import PearlApiService from '~/composables/PearlApiService';
import { Tokens, tokensConfig } from '~/utils/constants';
import useEnvs from '~/composables/useEnvs';
import type { ITokenModel } from '~/types/apiService';
import { useTokensReader } from '#imports';

// TODO: make it as reusable function
function format(value: string, decimals?: number): string {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
}

const { blockchain, apiUrl } = useEnvs();
const { address } = useWeb3ModalAccount();
const { staked, availableTokens } = useTokensReader();

const props = defineProps<{
  token: ITokenModel;
}>();

const totalStaked = ref('0');
const balance = ref('0');
const apr = ref('0');

onBeforeMount(() => {
  return { address: address.value };
});

onMounted(async () => {
  const [tokenTotalStaked, tokenAvailableBalance, aprPercentage] = await Promise.all([
    staked(blockchain.contracts.treasury, props.token.key),
    availableTokens(address.value || '', blockchain.contracts[props.token.key].addresses.contract),
    PearlApiService.getAprPercent(apiUrl, props.token.tokenContractAddress)
  ]);

  totalStaked.value = new BigNumber(tokenTotalStaked).toString();
  balance.value = new BigNumber(tokenAvailableBalance).toString();
  apr.value = new BigNumber(aprPercentage.apr ?? 0).toString();
});
</script>

<style lang="scss" scoped>
.token {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #041a27 0%, #041a27 100%), #14232a;
}

.optionItem {
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 32px;
  font-weight: 400;
}

.tokenTitle {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 110px;
}

.tokenInfoBlocks {
  display: flex;
  gap: 10px;
  width: calc(100% - 85px);
}

.tokenInfoBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #0d242f 0%, #0d242f 100%), linear-gradient(0deg, #10364a 0%, #10364a 100%), #14232a;

  .blockItem {
    display: flex;
    flex-direction: column;

    &-stats-link {
      margin-top: auto;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-color: #fff;
      border-radius: 48px;
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      img {
        height: 12px;
        width: 12px;
        margin-left: 8px;
      }
    }
  }
  .heading {
    color: #8e979c;
    font-family: Grenze, sans-serif;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    line-height: 1;
  }

  .value {
    color: #fff;
    font-family: Grenze, sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
  }
}
</style>
