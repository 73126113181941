export const ANIMATED_ASSETS = [
  {
    name: 'Ship_battle',
    animations: [
      { value: '0', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '1', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '2', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '3', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '4', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '5', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '6', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '7', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '8', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '9', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '10', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '11', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] },
      { value: '12', animation: ['ships-battle-Fix-fire', 'ships-battle-end'] }
    ]
  },
  {
    name: 'dock1',
    animations: [
      { value: '0', animation: ['flag-1-green'] },
      { value: '1', animation: ['flag-1-green'] },
      { value: '2', animation: ['flag-1-green'] },
      { value: '3', animation: ['flag-2-blue'] },
      { value: '4', animation: ['flag-2-blue'] },
      { value: '5', animation: ['flag-2-blue'] },
      { value: '6', animation: ['flag-3-yellow'] },
      { value: '7', animation: ['flag-3-yellow'] },
      { value: '8', animation: ['flag-3-yellow'] },
      { value: '9', animation: ['flag-4-red'] },
      { value: '10', animation: ['flag-4-red'] },
      { value: '11', animation: ['flag-5-black'] },
      { value: '12', animation: ['flag-5-black'] }
    ]
  },
  {
    name: 'ship_1',
    animations: [
      { value: '0', animation: ['ship_1_idleFlag'] },
      { value: '1', animation: ['ship_1_idleFlag'] },
      { value: '2', animation: ['ship_1_idleFlag'] },
      { value: '3', animation: ['ship_1_idleFlag'] },
      { value: '4', animation: ['ship_1_idleFlag'] },
      { value: '5', animation: ['ship_1_idleFlag'] },
      { value: '6', animation: ['ship_1_idleFlag'] },
      { value: '7', animation: ['ship_1_idleFlag'] },
      { value: '8', animation: ['ship_1_idleFlag'] },
      { value: '9', animation: ['ship_1_idleFlag'] },
      { value: '10', animation: ['ship_1_idleFlag'] },
      { value: '11', animation: ['ship_1_idleFlag'] },
      { value: '12', animation: ['ship_1_idleFlag'] }
    ]
  },
  {
    name: 'Ship_2',
    animations: [
      { value: '0', animation: ['ship_2_idleFlag'] },
      { value: '1', animation: ['ship_2_idleFlag'] },
      { value: '2', animation: ['ship_2_idleFlag'] },
      { value: '3', animation: ['ship_2_idleFlag'] },
      { value: '4', animation: ['ship_2_idleFlag'] },
      { value: '5', animation: ['ship_2_idleFlag'] },
      { value: '6', animation: ['ship_2_idleFlag'] },
      { value: '7', animation: ['ship_2_idleFlag'] },
      { value: '8', animation: ['ship_2_idleFlag'] },
      { value: '9', animation: ['ship_2_idleFlag'] },
      { value: '10', animation: ['ship_2_idleFlag'] },
      { value: '11', animation: ['ship_2_idleFlag'] },
      { value: '12', animation: ['ship_2_idleFlag'] }
    ]
  },
  {
    name: 'Ship_3',
    animations: [
      { value: '0', animation: ['ship_3_workingFlag'] },
      { value: '1', animation: ['ship_3_workingFlag'] },
      { value: '2', animation: ['ship_3_workingFlag'] },
      { value: '3', animation: ['ship_3_workingFlag'] },
      { value: '4', animation: ['ship_3_workingFlag'] },
      { value: '5', animation: ['ship_3_workingFlag'] },
      { value: '6', animation: ['ship_3_workingFlag'] },
      { value: '7', animation: ['ship_3_workingFlag'] },
      { value: '8', animation: ['ship_3_workingFlag'] },
      { value: '9', animation: ['ship_3_idleSailsUpFlag'] },
      { value: '10', animation: ['ship_3_idleSailsUpFlag'] },
      { value: '11', animation: ['ship_3_idleSailsUpFlag'] },
      { value: '12', animation: ['ship_3_idleSailsUpFlag'] }
    ]
  },
  {
    name: 'Ship_2_orange',
    animations: [
      { value: '0', animation: ['ship_2_workingFlag'] },
      { value: '1', animation: ['ship_2_workingFlag'] },
      { value: '2', animation: ['ship_2_workingFlag'] },
      { value: '3', animation: ['ship_2_workingFlag'] },
      { value: '4', animation: ['ship_2_workingFlag'] },
      { value: '5', animation: ['ship_2_workingFlag'] },
      { value: '6', animation: ['ship_2_workingFlag'] },
      { value: '7', animation: ['ship_2_workingFlag'] },
      { value: '8', animation: ['ship_2_workingFlag'] },
      { value: '9', animation: ['ship_2_idleSailsUpFlag'] },
      { value: '10', animation: ['ship_2_idleSailsUpFlag'] },
      { value: '11', animation: ['ship_2_idleSailsUpFlag'] },
      { value: '12', animation: ['ship_2_idleSailsUpFlag'] }
    ]
  },
  {
    name: 'Boat_1',
    animations: [
      { value: '0', animation: ['boat-1-float'] },
      { value: '1', animation: ['boat-1-float'] },
      { value: '2', animation: ['boat-1-float'] },
      { value: '3', animation: ['boat-1-float'] },
      { value: '4', animation: ['boat-1-float'] },
      { value: '5', animation: ['boat-1-float'] },
      { value: '6', animation: ['boat-1-float'] },
      { value: '7', animation: ['boat-1-float'] },
      { value: '8', animation: ['boat-1-float'] },
      { value: '9', animation: ['boat-1-float'] },
      { value: '10', animation: ['boat-1-float'] },
      { value: '11', animation: ['boat-1-float'] },
      { value: '12', animation: ['boat-1-float'] }
    ]
  },
  {
    name: 'raft-1',
    animations: [
      { value: '0', animation: ['raft-1-float'] },
      { value: '1', animation: ['raft-1-float'] },
      { value: '2', animation: ['raft-1-float'] },
      { value: '3', animation: ['raft-1-float'] },
      { value: '4', animation: ['raft-1-float'] },
      { value: '5', animation: ['raft-1-float'] },
      { value: '6', animation: ['raft-1-float'] },
      { value: '7', animation: ['raft-1-float'] },
      { value: '8', animation: ['raft-1-float'] },
      { value: '9', animation: ['raft-1-float'] },
      { value: '10', animation: ['raft-1-float'] },
      { value: '11', animation: ['raft-1-float'] },
      { value: '12', animation: ['raft-1-float'] }
    ]
  },
  {
    name: 'boat-6',
    animations: [
      { value: '0', animation: ['boat-6-float'] },
      { value: '1', animation: ['boat-6-float'] },
      { value: '2', animation: ['boat-6-float'] },
      { value: '3', animation: ['boat-6-float'] },
      { value: '4', animation: ['boat-6-float'] },
      { value: '5', animation: ['boat-6-float'] },
      { value: '6', animation: ['boat-6-float'] },
      { value: '7', animation: ['boat-6-float'] },
      { value: '8', animation: ['boat-6-float'] },
      { value: '9', animation: ['boat-6-float'] },
      { value: '10', animation: ['boat-6-float'] },
      { value: '11', animation: ['boat-6-float'] },
      { value: '12', animation: ['boat-6-float'] }
    ]
  },
  {
    name: 'Boat-7',
    animations: [
      { value: '0', animation: ['Boat-7-float'] },
      { value: '1', animation: ['Boat-7-float'] },
      { value: '2', animation: ['Boat-7-float'] },
      { value: '3', animation: ['Boat-7-float'] },
      { value: '4', animation: ['Boat-7-float'] },
      { value: '5', animation: ['Boat-7-float'] },
      { value: '6', animation: ['Boat-7-float'] },
      { value: '7', animation: ['Boat-7-float'] },
      { value: '8', animation: ['Boat-7-float'] },
      { value: '9', animation: ['Boat-7-float'] },
      { value: '10', animation: ['Boat-7-float'] },
      { value: '11', animation: ['Boat-7-float'] },
      { value: '12', animation: ['Boat-7-float'] }
    ]
  },
  {
    name: 'raft-2',
    animations: [
      { value: '0', animation: ['raft-2-float'] },
      { value: '1', animation: ['raft-2-float'] },
      { value: '2', animation: ['raft-2-float'] },
      { value: '3', animation: ['raft-2-float'] },
      { value: '4', animation: ['raft-2-float'] },
      { value: '5', animation: ['raft-2-float'] },
      { value: '6', animation: ['raft-2-float'] },
      { value: '7', animation: ['raft-2-float'] },
      { value: '8', animation: ['raft-2-float'] },
      { value: '9', animation: ['raft-2-float'] },
      { value: '10', animation: ['raft-2-float'] },
      { value: '11', animation: ['raft-2-float'] },
      { value: '12', animation: ['raft-2-float'] }
    ]
  },
  {
    name: 'boat-4',
    animations: [
      { value: '0', animation: ['boat-4-float'] },
      { value: '1', animation: ['boat-4-float'] },
      { value: '2', animation: ['boat-4-float'] },
      { value: '3', animation: ['boat-4-float'] },
      { value: '4', animation: ['boat-4-float'] },
      { value: '5', animation: ['boat-4-float'] },
      { value: '6', animation: ['boat-4-float'] },
      { value: '7', animation: ['boat-4-float'] },
      { value: '8', animation: ['boat-4-float'] },
      { value: '9', animation: ['boat-4-float'] },
      { value: '10', animation: ['boat-4-float'] },
      { value: '11', animation: ['boat-4-float'] },
      { value: '12', animation: ['boat-4-float'] }
    ]
  },
  {
    name: 'palm1',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm2',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm3',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm4',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm5',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm6',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm7',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'palm8',
    animations: [
      { value: '0', animation: ['1_idle'] },
      { value: '1', animation: ['1_idle'] },
      { value: '2', animation: ['1_idle'] },
      { value: '3', animation: ['2_slow'] },
      { value: '4', animation: ['2_slow'] },
      { value: '5', animation: ['3_normal'] },
      { value: '6', animation: ['3_normal'] },
      { value: '7', animation: ['3_normal'] },
      { value: '8', animation: ['4_fast'] },
      { value: '9', animation: ['4_fast'] },
      { value: '10', animation: ['4_fast'] },
      { value: '11', animation: ['5_storm'] },
      { value: '12', animation: ['5_storm'] }
    ]
  },
  {
    name: 'tradetent1',
    animations: [
      { value: '0', animation: ['TradeTentDonkeyIdle'] },
      { value: '1', animation: ['TradeTentDonkeyIdle'] },
      { value: '2', animation: ['TradeTentDonkeyIdle'] },
      { value: '3', animation: ['TradeTentDonkeyIdle'] },
      { value: '4', animation: ['TradeTentDonkeyIdle'] },
      { value: '5', animation: ['TradeTentDonkeyIdle'] },
      { value: '6', animation: ['TradeTentDonkeyIdle'] },
      { value: '7', animation: ['TradeTentDonkeyIdle'] },
      { value: '8', animation: ['TradeTentDonkeyIdle'] },
      { value: '9', animation: ['TradeTentDonkeyIdle'] },
      { value: '10', animation: ['TradeTentDonkeyIdle'] },
      { value: '11', animation: ['TradeTentDonkeyIdle'] },
      { value: '12', animation: ['TradeTentDonkeyIdle'] }
    ]
  },
  {
    name: 'waterWave1',
    animations: [
      { value: '0', animation: ['animation'] },
      { value: '1', animation: ['animation'] },
      { value: '2', animation: ['animation'] },
      { value: '3', animation: ['animation'] },
      { value: '4', animation: ['animation'] },
      { value: '5', animation: ['animation'] },
      { value: '6', animation: ['animation'] },
      { value: '7', animation: ['animation'] },
      { value: '8', animation: ['animation'] },
      { value: '9', animation: ['animation'] },
      { value: '10', animation: ['animation'] },
      { value: '11', animation: ['animation'] },
      { value: '12', animation: ['animation'] }
    ]
  },
  {
    name: 'waterWave2',
    animations: [
      { value: '0', animation: ['animation'] },
      { value: '1', animation: ['animation'] },
      { value: '2', animation: ['animation'] },
      { value: '3', animation: ['animation'] },
      { value: '4', animation: ['animation'] },
      { value: '5', animation: ['animation'] },
      { value: '6', animation: ['animation'] },
      { value: '7', animation: ['animation'] },
      { value: '8', animation: ['animation'] },
      { value: '9', animation: ['animation'] },
      { value: '10', animation: ['animation'] },
      { value: '11', animation: ['animation'] },
      { value: '12', animation: ['animation'] }
    ]
  },
  {
    name: 'waterWave3',
    animations: [
      { value: '0', animation: ['animation'] },
      { value: '1', animation: ['animation'] },
      { value: '2', animation: ['animation'] },
      { value: '3', animation: ['animation'] },
      { value: '4', animation: ['animation'] },
      { value: '5', animation: ['animation'] },
      { value: '6', animation: ['animation'] },
      { value: '7', animation: ['animation'] },
      { value: '8', animation: ['animation'] },
      { value: '9', animation: ['animation'] },
      { value: '10', animation: ['animation'] },
      { value: '11', animation: ['animation'] },
      { value: '12', animation: ['animation'] }
    ]
  },
  {
    name: 'waterWave4',
    animations: [
      { value: '0', animation: ['animation'] },
      { value: '1', animation: ['animation'] },
      { value: '2', animation: ['animation'] },
      { value: '3', animation: ['animation'] },
      { value: '4', animation: ['animation'] },
      { value: '5', animation: ['animation'] },
      { value: '6', animation: ['animation'] },
      { value: '7', animation: ['animation'] },
      { value: '8', animation: ['animation'] },
      { value: '9', animation: ['animation'] },
      { value: '10', animation: ['animation'] },
      { value: '11', animation: ['animation'] },
      { value: '12', animation: ['animation'] }
    ]
  }
];
